import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { StyledLink } from '../common/link'
import { useStaticQuery, graphql } from "gatsby"


import { device  } from '../../utils/breakpoints'

import { Post } from './post'



const StyledWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.grayWhite};
`

const StyledContainer = styled(Container) `
    gap: 2rem;
`

const StyledHeading = styled(Heading) `

`

const ContainerPosts = styled.div `
    display: grid;
    gap: 2rem;
    grid-template-columns: auto;


    @media ${device.tablet} { 
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.laptopL} { 
        grid-template-columns: repeat(3, 1fr);
    }
`


export const Blog = () => {

    const data = useStaticQuery(graphql`
    {
        allContentfulBlogPost {
          nodes {
            zdjecie {
              gatsbyImageData(layout: CONSTRAINED)
                title
                description
            }
            id
            tytul
            krotkiOpis {
              krotkiOpis
            }
            slug
          }
        }






      }
    `)

    const blogPosts = data.allContentfulBlogPost.nodes


    return (
        <StyledWrapper>
            <StyledContainer>
                <StyledHeading h1 as='h1'>Kochana przeczytaj nasze artykuły!</StyledHeading>

                <ContainerPosts>
                    {blogPosts && (blogPosts.map((blogPost) => {
                        return (
                            <Post key={blogPost.id} blogPost={blogPost} />
                        )
                    }))}
                </ContainerPosts>
            </StyledContainer>
        </StyledWrapper>
    )
}