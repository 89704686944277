import React from "react"
import Seo from '../components/seo'

import Layout from '../components/layout'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import { Blog } from '../components/blog'


const BydgoszczBlog = () => (
  <Layout bydgoszcz>
    <Seo
      title="Bydgoszcz Blog"
      description="Blog BodyMed Group to miejsce, w którym dowiesz się więcej o zabiegach i poznasz tajniki pielęgnacji skóry twarzy i ciała w domu. Zapraszamy!"
      url="https://bodymedgroup.pl/bydgoszcz-blog"
    />
    <SmallTitlePage
      title='Blog' 
    />
    <Blog/>
  </Layout>
)

export default BydgoszczBlog
